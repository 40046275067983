import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  noop, filter,
} from 'lodash';
import {
  FINANCE_BANK_JOURNAL_FORM_FIELDS,
} from '../../../../../constants/finance/finance.constant';
import ListForm from '../../../../../components/ListForm/ListForm.component';

export default function BankJournalFormHeader(props) {
  const {
    form, coa, onFormChange, isDebit, onSearchUnit,
    onSearchBankAccount, isReconciliation, user,
  } = props;
  const { prm_school_units_id: unit_id } = user;
  const { user_group } = user;
  const { permissions } = user_group;
  const { value } = form;
  const { tipe = 0, is_posted = false } = value;
  let fields = { ...FINANCE_BANK_JOURNAL_FORM_FIELDS };
  const journalType = filter(fields, o => o.name === 'type');
  const formType = filter(fields, o => o.name === 'tipe');
  const rekeningSelector = filter(fields, o => o.name === 'bank_account');
  const dateSelector = filter(fields, o => o.name === 'date');
  const unitSelector = filter(fields, o => o.name === 'unit');

  dateSelector[0].disabled = false;

  if (!isReconciliation) {
    if (permissions.includes('backdate_journal') || ((unit_id === 94 || unit_id === 1) && !is_posted)) {
      dateSelector[0].disabled = false;
    }
    if (!isDebit) {
      formType[0].data = [
        { label: 'Standard', value: '1' },
      ];
    } else {
      formType[0].data = [
        { label: 'Standard', value: '1' },
        { label: 'Rekonsiliasi', value: '2' },
      ];
    }
    journalType[0].disabled = false;
    formType[0].disabled = false;
  } else {
    journalType[0].disabled = true;
    formType[0].disabled = true;
    dateSelector[0].disabled = true;
    formType[0].data = [
      { label: 'Rekonsiliasi', value: '2' },
    ];
  }

  rekeningSelector[0].handleSearchContent = onSearchBankAccount;
  rekeningSelector[0].async = true;

  if (tipe === '2') {
    unitSelector[0].label = (isDebit) ? 'Unit Sumber' : 'Unit Tujuan';
    unitSelector[0].handleSearchContent = onSearchUnit;
    unitSelector[0].async = true;
    unitSelector[0].disabled = isReconciliation;
    fields = filter(fields, field => field.name !== 'pos');
  } else {
    fields = filter(fields, o => o.name !== 'unit');
  }


  return (
    <ListForm
      form={form}
      coa={coa}
      formFields={fields}
      onFormChange={onFormChange}
    />
  );
}


BankJournalFormHeader.propTypes = {
  form: PropTypes.object,
  coa: PropTypes.object,
  onFormChange: PropTypes.func,
  isDebit: PropTypes.bool,
  onSearchUnit: PropTypes.func,
  onSearchBankAccount: PropTypes.func,
  isReconciliation: PropTypes.bool,
  user: PropTypes.object,
};

BankJournalFormHeader.defaultProps = {
  form: {},
  coa: {},
  onFormChange: noop,
  isDebit: false,
  onSearchUnit: noop,
  onSearchBankAccount: noop,
  isReconciliation: false,
  user: {},
};
