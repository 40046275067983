export const STAFF_TABLE_FIELDS = {
  label: [
    "No",
    "NIP",
    "Nama Pegawai",
    "Tgl Lahir",
    "Status Pegawai",
    "Pangkat / Golongan",
    "Masa Kerja",
    "Jabatan",
    "Action",
  ],
  value: [
    {},
    { type: "string", attribute: "nip" },
    { type: "string", attribute: "nama_lengkap" },
    { type: "date", attribute: "tanggal_lahir" },
    { type: "string", attribute: "status_kepegawaian.name" },
    { type: "string", attribute: "golongan.id_golongan" },
    { type: "string", attribute: "masa_kerja_saat_ini" },
    { type: "string", attribute: "jabatan.name" },
  ],

  //value: [{}, { type: 'string', attribute: 'periode' }, { type: 'string', attribute: 'desc', disabled: true }],
};

export const MANAGE_STAFF_FORM = [
  {
    // form identitas pegawai start here
    type: "title",
    text: "Identitas Pegawai",
    full: true,
  },
  {
    type: "text",
    name: "nip",
    label: "NIP",
    placeholder: "",
  },
  {
    type: "text",
    name: "nama_lengkap",
    label: "Nama Lengkap Pegawai",
    placeholder: "",
  },
  {
    type: "text",
    name: "nama_panggilan",
    label: "Nama Panggilan",
    placeholder: "",
  },
  {
    type: "select",
    name: "jenis_kelamin",
    label: "Jenis Kelamin",
    placeholder: "Pilih Jenis Kelamin",
    data: [
      { label: "Laki-Laki", value: "laki_laki" },
      { label: "Perempuan", value: "perempuan" },
    ],
  },
  {
    type: "text",
    name: "jabatan_tugas_tambahan",
    label: "Jabatan Tugas Tambahan",
    placeholder: "",
  },
  {
    type: "text",
    name: "id_absensi_kartu_fingerprint",
    label: "ID Absensi / Fingerprint / Kartu",
    placeholder: "",
  },
  {
    type: "dualInput",
    leftName: "tempat_lahir",
    leftLabel: "Tempat / Tanggal Lahir",
    leftPlaceholder: "",
    rightType: "date",
    rightName: "tanggal_lahir",
    rightLabel: "\u00A0",
  },
  {
    type: "text",
    name: "email",
    label: "Email",
    placeholder: "",
  },
  {
    type: "dualInput",
    leftName: "rt",
    leftLabel: "RT/ RW",
    leftPlaceholder: "",
    rightName: "rw",
    rightLabel: "\u00A0",
    rightPlaceholder: "",
  },
  {
    type: "dualInput",
    leftName: "tinggi",
    leftLabel: "Tinggi Badan/ Berat Badan",
    leftPlaceholder: "",
    rightName: "berat",
    rightLabel: "\u00A0",
    rightPlaceholder: "",
  },
  {
    type: "text",
    name: "kecamatan",
    label: "Kecamatan",
    placeholder: "",
  },
  {
    type: "text",
    name: "alamat_tempat_tinggal",
    label: "Alamat",
    placeholder: "",
  },
  {
    type: "text",
    name: "propinsi",
    label: "Provinsi",
    placeholder: "",
  },
  {
    type: "text",
    name: "kelurahan",
    label: "Kelurahan",
    placeholder: "",
  },
  {
    type: "select",
    name: "status_kepemilikan_rumah",
    label: "Status Kepemilikan Rumah",
    placeholder: "Pilih Status Kepemilikan Rumah",
    data: [
      { label: "Milik Sendiri", value: "milik_sendiri" },
      { label: "Sewa dan kontrak", value: "sewa_kontrak" },
    ],
  },

  {
    type: "text",
    name: "kotamadya_kabupaten",
    label: "Kotamadya / Kabupaten",
    placeholder: "",
  },
  {
    type: "text",
    name: "no_wa",
    label: "No WA",
    placeholder: "",
  },
  {
    type: "text",
    name: "no_telpon",
    label: "Telpon / HP",
    placeholder: "",
  },
  {
    type: "select",
    name: "kewarganegaraan",
    label: "Kewarganegaraan",
    placeholder: "Pilihan",
    data: [
      { label: "WNI", value: "wni" },
      { label: "WNA", value: "wna" },
    ],
  },
  {
    type: "select",
    name: "agama",
    label: "Agama",
    placeholder: "Pilih Agama",
    data: [
      { label: "Budha", value: "budha" },
      { label: "Hindu", value: "hindu" },
      { label: "Katolik", value: "katolik" },
      { label: "Kristen", value: "kristen" },
      { label: "Islam", value: "islam" },
    ],
  },
  {
    type: "text",
    name: "pendidikan_terakhir",
    label: "Pendidikan Terakhir",
    placeholder: "",
  },
  {
    type: "select",
    name: "golongandarah",
    label: "Golongan Darah",
    placeholder: "Pilih Golongan Darah",
    data: [
      { label: "A", value: "a" },
      { label: "B", value: "b" },
      { label: "O", value: "o" },
      { label: "AB", value: "ab" },
    ],
  },
  {
    type: "text",
    name: "penyakit_yg_diderita",
    label: "Penyakit Yang Diderita",
    placeholder: "",
  },
  {
    type: "text",
    name: "bahasa",
    label: "Bahasa Yang dikuasai",
    placeholder: "",
  },
  {
    type: "text",
    name: "golongan",
    label: "Pangkat / Golongan",
  },
  {
    type: "text",
    name: "kelainan_fisik",
    label: "Kelainan Fisik",
    placeholder: "",
  },
  // form identitas pegawai end here

  // Form data Kepegawaian

  {
    type: "empty",
  },
  {
    type: "title",
    text: "Data Kepegawaian",
    full: true,
  },
  {
    type: "text",
    name: "nik_ktp",
    label: "NIK KTP",
    placeholder: "",
  },
  {
    type: "text",
    name: "nik_kk",
    label: "NIK KK",
    placeholder: "",
  },
  {
    type: "text",
    name: "npwp",
    label: "NPWP",
    placeholder: "",
  },
  {
    type: "dualInput",
    leftName: "bank",
    leftLabel: "No Rekening Bank / Nomor",
    leftPlaceholder: "",
    rightName: "no_rek_bank",
    rightLabel: "\u00A0",
    rightPlaceholder: "",
  },
  {
    type: "select",
    name: "unit_kerja",
    label: "Unit Kerja",
    placeholder: "Pilih Unit Kerja",
    data: [
      { label: "Ricci I TK/Pre", value: "ricci_I_tk/pre" },
      { label: "Ricci I SD", value: "ricci_I_sd" },
      { label: "Ricci I SMP", value: "ricci_I_sma" },
      { label: "Ricci I SMA", value: "ricci_I_sma" },
      { label: "Ricci II TK/Pre", value: "ricci_II_tk/pre" },
      { label: "Ricci II SD", value: "ricci_II_sd" },
      { label: "Ricci II SMP", value: "ricci_II_sma" },
      { label: "Ricci II SMA", value: "ricci_II_sma" },
    ],
  },
  {
    type: "select",
    name: "jabatan",
    label: "Jabatan",
    placeholder: "Pilih Jabatan",
    data: [
      { label: "Ketua Pengurus Yayasan 1", value: "ketua_pengurus_yayasan1" },
      { label: "Ketua Pengurus Yayasan 2", value: "ketua_pengurus_yayasan2" },
      { label: "Ketua Pengurus Yayasan 3", value: "ketua_pengurus_yayasan3" },
      { label: "Ketua Pengurus Yayasan 4", value: "ketua_pengurus_yayasan4" },
    ],
  },
  {
    type: "select",
    name: "aktif_non_aktif",
    label: "Aktif/Non Aktif",
    placeholder: "",
    data: [
      { label: "Aktif", value: "Aktif" },
      { label: "Non Aktif", value: "Non Aktif" },
      { label: "Pensiun", value: "Pensiun" },
    ],
  },
  {
    type: "select",
    name: "status_kepegawaian_gaji",
    label: "Status Kepegawaian/Gaji",
    placeholder: "",
    data: [
      { label: "Pegawai Tetap", value: "pegawai_tetap" },
      { label: "Pegawai Kontrak", value: "pegawai_kontrak" },
    ],
  },
  {
    type: "text",
    name: "golongan",
    label: "Golongan",
    placeholder: "",
  },
  {
    type: "text",
    name: "tunjangan_keluarga",
    label: "Tunjangan Keluarga",
    placeholder: "",
  },
  {
    type: "date",
    name: "tanggal_mulai_bekerja",
    label: "Tanggal Masuk",
    placeholder: "",
  },
  {
    type: "text",
    name: "masa_kerja",
    label: "Masa Kerja Saat ini",
    placeholder: "",
  },
  {
    type: "date",
    name: "tgl_keluar_pensiun",
    label: "Tanggal Keluar/pensiun",
    placeholder: "",
  },
  {
    type: "text",
    name: "keterangan_keluar",
    label: "Keterangan Keluar/pensiun",
    placeholder: "",
  },
  {
    type: "text",
    name: "no_yadapen",
    label: "No Yadapen",
    placeholder: "",
  },
  {
    type: "text",
    name: "apd",
    label: "APD",
    placeholder: "",
  },
  {
    type: "dualInput",
    leftName: "status_kepersetaan",
    leftLabel: "BPJS Ketenagakerjaan",
    leftPlaceholder: "Status Kepesertaan",
    rightName: "no_peserta",
    rightLabel: "\u00A0",
    rightPlaceholder: "No Peserta",
  },
  {
    type: "dualInput",
    leftName: "status_kepersetaan",
    leftLabel: "BPJS Kesehatan",
    leftPlaceholder: "Status Kepesertaan",
    rightName: "no_peserta",
    rightLabel: "\u00A0",
    rightPlaceholder: "No Peserta",
  },

  // Form data Kepegawaian

  // {
  //   type: 'dualInput',
  //   leftName: 'asuransi',
  //   leftLabel: 'Asuransi Provider / Nomor',
  //   leftPlaceholder: '',
  //   rightName: 'no_asuransi',
  //   rightLabel: '\u00A0',
  //   rightPlaceholder: '',
  // }, {
  //   type: 'text',
  //   inputType: 'number',
  //   name: 'dana_pensiun',
  //   label: 'Dana Pensiun',
  //   placeholder: '',
  // }, {
  //   type: 'text',
  //   name: 'perusahaan',
  //   label: 'Perusahaan',
  //   placeholder: '',
  // },
  // {
  //   type: 'text',
  //   name: 'unit',
  //   label: 'Unit',
  //   placeholder: '',
  // },
  // {
  //   type: 'text',
  //   name: 'fungsional',
  //   label: 'Fungsional',
  //   placeholder: '',
  // }, {
  //   type: 'text',
  //   name: 'statusKepegawaian',
  //   label: 'Status Kepegawaian',
  //   placeholder: '',
  // },
  // {
  //   type: 'text',
  //   name: 'jabatan',
  //   label: 'Jabatan',
  //   placeholder: '',
  // },
  // {
  //   type: 'text',
  //   name: 'pekerjaan',
  //   label: 'Pekerjaan',
  //   placeholder: '',
  // }, {
  //   type: 'text',
  //   name: 'karyawan_dpk',
  //   label: 'Karyawan DPK',
  //   placeholder: '',
  // }
];

export const FORMAL_BPJS_KESEHATAN_FORM = {
  tableHead: ["No.", "Nama ", "Status Kepesertaan", "No Peserta"],
  name: [
    "",
    { name: "name" },
    {
      name: "status_kepesertaan",
      type: "select",
      placeholder: "Pilih Status Kepesertaan",
      data: [
        { label: "Penerima Upah (PPU)", value: "penerima_upah" },
        { label: "Bukan Penerima Upah (PBPU)", value: "bukan_penerima_upah" },
        { label: "Bukan Pekerja (BP)", value: "bukan_pekerja" },
      ],
    },
    { name: "no_peserta" },
  ],
};

export const FORMAL_EDUCATION_FORM = {
  tableHead: [
    "No.",
    "Level",
    "Nama Sekolah",
    "Kota",
    "Jurusan",
    "Tahun Masuk",
    "Tahun Lulus",
  ],
  name: [
    "",
    {
      name: "jenjangpendidikan",
      type: "select",
      placeholder: "Pilih Jenjang Pendidikan",
      data: [{ label: "SD", value: "SD" }],
    },
    { name: "nama_sekolah" },
    { name: "kota" },
    { name: "jurusan" },
    { name: "dari_tahun" },
    { name: "sampai_tahun" },
  ],
};

export const FORMAL_POTONGAN_YPL = {
  tableHead: ["No.", "Jenis Potongan", "Nominal"],
  name: ["", { name: "jenis_potongan" }, { name: "nominal" }],
};

export const FORMAL_POTONGAN_INTERN_SEKOLAH = {
  tableHead: ["No.", "Jenis Potongan", "Nominal"],
  name: ["", { name: "jenis_potongan" }, { name: "nominal" }],
};

export const NON_FORMAL_EDUCATION_FORM = {
  tableHead: [
    "No.",
    "Nama Training",
    "Lembaga keluarga international yuhuuuu",
    "Kota",
    "Tahun",
    "Lama",
    "",
  ],
  name: [
    "",
    { name: "nama_training" },
    { name: "lembaga" },
    { name: "kota" },
    { name: "tahun" },
    { name: "lama" },
  ],
};

export const JOB_HISTORY_FORM = {
  tableHead: [
    "No.",
    "Nama Perusahaan keluarga",
    "Kota",
    "Jabatan",
    "Tahun Masuk",
    "Tahun Keluar",
    "Alasan Keluar",
  ],
  name: [
    "",
    { name: "perusahaan" },
    { name: "kota" },
    { name: "jabatan" },
    { name: "tahun" },
    { name: "sampai_tahun" },
    { name: "keterangan" },
  ],
};

export const ORGANIZATION_FORM = {
  tableHead: ["No.", "Organisasi", "Tempat", "Tahun", "Keterangan"],
  name: [
    "",
    { name: "organisasi" },
    { name: "tempat" },
    { name: "tanggal", type: "date" },
    { name: "keterangan" },
  ],
};

export const FAMILY_FORM = {
  tableHead: [
    "No.",
    "Nama Suami/Istri/Anak",
    "Tanggal Lahir",
    "Agama",
    "Pendidikan",
    "Status",
    "Tunjangan Keluarga",
  ],
  name: [
    "",
    { name: "nama_lengkap" },
    { name: "tanggal_lahir", type: "date" },
    {
      name: "agama",
      type: "select",
      placeholder: "Pilih Agama",
      data: [
        { label: "Budha", value: "budha" },
        { label: "Hindu", value: "hindu" },
        { label: "Katolik", value: "katolik" },
        { label: "Kristen", value: "kristen" },
        { label: "Islam", value: "islam" },
      ],
    },
    { name: "pendidikan" },
    { name: "status" },
    {
      name: "tertanggung",
      type: "select",
      placeholder: "Pilihan",
      data: [
        { label: "Tertanggung", value: "tertanggung" },
        { label: "Tidak Tertanggu", value: "tidak Tertanggu" },
      ],
    },
  ],
};

export const MEMORANDUM_FORM = {
  tableHead: [
    "No.",
    "level",
    "Tanggal",
    "No Surat",
    "Jenis Pelanggaran",
    "Keterangan",
  ],
  name: [
    "",
    { name: "level" },
    { name: "tanggal", type: "date" },
    { name: "no_surat" },
    { name: "jenis_pelanggaran" },
    { name: "keterangan" },
  ],
};

// export const WARNING_LETTER_FORM = {
//   tableHead: ['No.', 'No Surat','Tanggal', 'Jenis Pelanggaran','Keterangan'],
//   name: [
//     '',
//     { name: 'no_surat' },
//     { name: 'tanggal', type: 'date' },
//     { name: 'jenis_pelanggaran' },
//     { name: 'keterangan' }],
// };

export const SABBATICAL_FORM = {
  tableHead: [
    "No.",
    "Alasan Cuti",
    "Tanggal",
    "Sampai Tanggal",
    "Keterangan",
    "",
  ],
  name: [
    "",
    { name: "alasan_cuti" },
    { name: "tanggal", type: "date" },
    { name: "sampai_tanggal", type: "date" },
    { name: "keterangan" },
  ],
};

export const UPLOAD_STAFF_FORM_FIELDS = [
  {
    type: "file",
    name: "file",
    label: "File",
    placeholder: "Pilih file",
    inputType: "file",
    full: true,
  },
];

export const MANAGE_JOB_APPLICATION_FORM_1 = [
  {
    type: "text",
    name: "nama",
    label: "Jabatan Yang Dilamar",
    placeholder: "",
  },
  {
    type: "text",
    name: "nama",
    label: "Pekerjaan / Jabatan Terakhir",
    placeholder: "",
  },
  {
    type: "title",
    text: "A. IDENTITAS",
    full: true,
  },
  {
    type: "text",
    name: "nama",
    label: "Nama Lengkap",
    placeholder: "",
  },
  {
    type: "text",
    name: "namaPanggilan",
    label: "Nama Panggilan",
    placeholder: "",
  },
  {
    type: "text",
    name: "gender",
    label: "Jenis Kelamin",
    placeholder: "",
  },
  {
    type: "dualInput",
    leftName: "tempat",
    leftLabel: "Tempat / Tanggal Lahir",
    leftPlaceholder: "",
    rightType: "date",
    rightName: "tanggalLahir",
    rightLabel: "\u00A0",
  },
  {
    type: "dualInput",
    leftName: "tinggiBadan",
    leftLabel: "Tinggi Badan / Berat Badan",
    leftPlaceholder: "",
    rightName: "beratBadan",
    rightLabel: "\u00A0",
    rightPlaceholder: "",
  },
  {
    type: "select",
    name: "golonganDarah",
    label: "Golongan Darah",
    placeholder: "Pilih Golongan Darah",
    data: [
      { label: "A", value: "a" },
      { label: "B", value: "b" },
      { label: "O", value: "o" },
      { label: "AB", value: "ab" },
    ],
  },
  {
    type: "select",
    name: "agama",
    label: "Agama",
    placeholder: "Pilih Agama",
    data: [
      { label: "Budha", value: "budha" },
      { label: "Hindu", value: "hindu" },
      { label: "Katolik", value: "katolik" },
      { label: "Kristen", value: "kristen" },
      { label: "Muslim", value: "muslim" },
    ],
  },
  {
    type: "dualInput",
    leftName: "tinggiBadan",
    leftLabel: "Suku / Bangsa",
    leftPlaceholder: "",
    rightName: "sukuBangsa",
    rightLabel: "\u00A0",
    rightPlaceholder: "",
  },
  {
    type: "text",
    name: "hobi",
    label: "Hobi",
    placeholder: "",
  },
  {
    type: "select",
    name: "perkawinan",
    label: "Status Perkawinan",
    placeholder: "Pilih Status",
    data: [
      { label: "Kawin", value: "kawin" },
      { label: "Belum Kawin", value: "belumKawin" },
      { label: "Duda", value: "duda" },
      { label: "Janda", value: "janda" },
    ],
  },
  {
    type: "textarea",
    name: "alamatAsal",
    label: "Alamat Asal",
  },
  {
    type: "text",
    name: "kodePosAsal",
    label: "Kode Pos Asal",
    placeholder: "",
  },
  {
    type: "text",
    name: "noTelpAsal",
    label: "No. Telp Asal",
    placeholder: "",
  },
  {
    type: "textarea",
    name: "alamatSekarang",
    label: "Alamat Sekarang",
  },
  {
    type: "text",
    name: "kodePosSekarang",
    label: "Kode Pos Sekarang",
    placeholder: "",
  },
  {
    type: "text",
    name: "noTelpSekarang",
    label: "No. Telp Sekarang",
    placeholder: "",
  },
  {
    type: "text",
    name: "namaIbu",
    label: "Nama Ibu",
    placeholder: "",
  },
  {
    type: "text",
    name: "simA",
    label: "Sim A",
    placeholder: "",
  },
  {
    type: "text",
    name: "simC",
    label: "Sim C",
    placeholder: "",
  },
  {
    type: "title",
    text: "Rumah / Tempat Tinggal",
    full: true,
  },
  {
    type: "select",
    isOther: true,
    name: "rumah",
    placeholder: "Pilih Tempat Tinggal",
    data: [
      { label: "Milik Sendiri", value: "1" },
      { label: "Milik Orang Tua", value: "2" },
      { label: "Sewa / Kontrak", value: "3" },
      { label: "Kos", value: "4" },
      { label: "Lain-lain", value: "other" },
    ],
  },
  {
    type: "title",
    text: "Kendaraan",
    full: true,
  },
  {
    type: "select",
    isOther: true,
    name: "kendaraan",
    label: "Jenis / Merek / Tahun",
    placeholder: "Pilih Kendaraan",
    data: [
      { label: "Milik Sendiri", value: "1" },
      { label: "Milik Orang Tua", value: "2" },
      { label: "Kantor", value: "3" },
      { label: "Lain-lain", value: "other" },
    ],
  },
];

export const STAFF_EDUCATION_FORM = {
  tableHead: [
    "No.",
    "Nama Sekolah",
    "Kota",
    "Jurusan",
    "Periode",
    "Keterangan",
  ],
  name: [
    "",
    { name: "namSekolah" },
    { name: "kota" },
    { name: "jurusan" },
    { name: "periode" },
    {
      name: "keterangan",
      type: "select",
      placeholder: "Pilih Keterangan",
      data: [
        { label: "Lulus", value: "1" },
        { label: "Tidak Lulus", value: "2" },
      ],
    },
  ],
};

export const STAFF_EXERCISE_FORM = {
  tableHead: ["No.", "Lembaga Penyelenggara", "Tahun", "Lamanya", "Dibiayai"],
  name: [
    "",
    { name: "lembaga" },
    { name: "tahun" },
    { name: "lamanya" },
    { name: "dibiayai" },
  ],
};

export const STAFF_LANGUAGE_FORM = {
  tableHead: ["No.", "Bahasa", "Membaca", "Menulis", "Bicara"],
  name: [
    "",
    { name: "bahasa" },
    { name: "membaca" },
    { name: "menulis" },
    { name: "bicara" },
  ],
};

export const MANAGE_JOB_APPLICATION_FORM_2__1 = [
  {
    type: "title",
    text: "E. PRESTASI YANG PERNAH DIRAIH",
    full: true,
  },
  {
    type: "textarea",
    name: "prestasi",
    full: true,
  },
];

export const STAFF_ORGANIZATION_FORM = {
  tableHead: ["No.", "Nama Organisasi", "Jabatan", "Tahun", "Kegiatan"],
  name: [
    "",
    { name: "name" },
    { name: "jabatan" },
    { name: "tahun" },
    { name: "kegiatan" },
  ],
};

export const STAFF_FAMILY_FORM = {
  tableHead: [
    "No.",
    "Nama",
    "Hubungan",
    "Jenis Kelamin",
    "Pendidikan",
    "Tempat / Tgl. Lahir",
  ],
  name: [
    "",
    { name: "name" },
    { name: "hub" },
    { name: "jenisKel" },
    { name: "pend" },
    { name: "tmpLahir" },
  ],
};

export const MANAGE_JOB_APPLICATION_FORM_2__2 = [
  {
    type: "text",
    name: "pekerjaan",
    label: "Pekerjaan",
  },
  {
    type: "text",
    name: "jabatan",
    label: "Jabatan",
  },
  {
    type: "text",
    name: "namaPerusahaan",
    label: "Nama Perusahaan",
  },
  {
    type: "text",
    name: "alamatPerusahaan",
    label: "Alamat Perusahaan",
  },
  {
    type: "select",
    name: "tanggungan",
    label:
      "Apakah anda mempunyai tanggungan selain anak & istri yang tersebut diatas?",
    placeholder: "Pilih",
    data: [
      { label: "Ya", value: "1" },
      { label: "Tidak", value: "2" },
    ],
  },
];

export const STAFF_FAMILY_RESPONSIBLE_FORM = {
  tableHead: [
    "No.",
    "Nama",
    "Hubungan",
    "Jenis Kelamin",
    "Pendidikan",
    "Tempat / Tgl. Lahir",
  ],
  name: [
    "",
    { name: "name" },
    { name: "hub" },
    { name: "jenisKel" },
    { name: "pend" },
    { name: "tmpLahir" },
  ],
};

export const STAFF_FAMILY_TREE_FORM = {
  tableHead: [
    "No.",
    "Nama",
    "Hubungan",
    "Jenis Kelamin",
    "Pendidikan",
    "Tempat / Tgl. Lahir",
  ],
  name: [
    "",
    { name: "name" },
    { name: "hub" },
    { name: "jenisKel" },
    { name: "pend" },
    { name: "tmpLahir" },
  ],
};

export const STAFF_EMERGENCY_CONTACT_FORM = {
  tableHead: ["No.", "Nama", "Alamat Kantor / Rumah", "Telepon", "Hubungan"],
  name: [
    "",
    { name: "name" },
    { name: "alamat" },
    { name: "telp" },
    { name: "hub" },
  ],
};

export const STAFF_REFERENCE_FORM = {
  tableHead: [
    "No.",
    "Nama",
    "Perusahaan",
    "Jabatan",
    "Alamat Kantor / Rumah",
    "Hubungan",
  ],
  name: [
    "",
    { name: "name" },
    { name: "perusahaan" },
    { name: "jabatan" },
    { name: "alamat" },
    { name: "hub" },
  ],
};

export const STAFF_JOB_EXPERIENCE_FORM = {
  tableHead: [
    "No.",
    "Nama Perusahaan",
    "Jenis Usaha",
    "Alamat",
    "Jabatan",
    "Gaji Terakhir",
    "Lama Bekerja",
    "Alasan Keluar",
  ],
  name: [
    "",
    { name: "name" },
    { name: "jenisUsaha" },
    { name: "alamat" },
    { name: "jabatan" },
    { name: "gajiTerakhir" },
    { name: "lamaBekerja" },
    { name: "alasanKeluar" },
  ],
};

export const MANAGE_JOB_APPLICATION_FORM_3 = [
  {
    type: "text",
    name: "rawat",
    label: "Apakah saudara/i pernah dirawat di rumah sakit?",
  },
  {
    type: "text",
    name: "bilaRawat",
    label: "Bila ya, sakit apa, sebutkan",
  },
  {
    type: "text",
    name: "kapanDirawat",
    label: "Kapan dan berapa lama dirawat",
  },
  {
    type: "text",
    name: "rawatJalan",
    label:
      "Apakah sodara/i sedang berobat jalan sehubungan dengan penyakit tertentu?",
  },
  {
    type: "text",
    name: "bilaRawatJalan",
    label: "Bila ya, sakit apa, sebutkan",
  },
  {
    type: "text",
    name: "kapanDirawatJalan",
    label: "Kapan dan berapa lama dirawat",
  },
  {
    type: "title",
    text:
      "L. URAIKAN TUGAS DAN TANGGUNG JAWAB ANDA PADA JABATAN ANDA YANG TERAKHIR",
    full: true,
  },
  {
    type: "textarea",
    name: "tugasTerakhir",
    full: true,
  },
  {
    type: "title",
    text:
      "M. GAMBARKAN POSISI ANDA DALAM STRUKTUR ORGANISASI PERUSAHAAN TEMPAT TERAKHIR BEKERJA",
    full: true,
  },
  {
    type: "file",
    name: "gambarOrganisasi",
    full: true,
  },
];

export const MANAGE_JOB_APPLICATION_FORM_4_1 = [
  {
    type: "title",
    text: "N. LAIN-LAIN",
    full: true,
  },
  {
    type: "text",
    name: "other1",
    label:
      "Apakah anda pernah melamar di yayasan richi sebelumnya? Apabila ya, kapan? Sebutlan posisi yang dilamar",
  },
  {
    type: "text",
    name: "other2",
    label:
      "Selain disini, diperusahaan / lembaga mana lagi anda melamar waktu ini? Sebutkan posisi yang dilamar",
  },
  {
    type: "text",
    name: "other3",
    label:
      "Apakah anda mempunyai pekerjaan sampingan / part time (terikat dengan perusahaan lain)? Apabila ya dimana dan posisi apa",
  },
  {
    type: "text",
    name: "other4",
    label:
      "Macam pekerjaan / jabatan apakah yang sesuai dengan cita cita anda?",
  },
  {
    type: "text",
    name: "other5",
    label: "Apa yang anda ketahui tentang yayasan richi?",
  },
  {
    type: "text",
    name: "other6",
    label: "Sebutkan orang yang anda kenal di yayasan richi?",
  },
  {
    type: "text",
    name: "other7",
    label: "Nama?",
  },
  {
    type: "text",
    name: "other8",
    label: "Hubungan dengan anda?",
  },
];

export const MANAGE_JOB_APPLICATION_FORM_4_2 = [
  {
    type: "text",
    name: "other9",
    label: "Gaji yang diharapkan?",
    inputType: "number",
  },
  {
    type: "text",
    name: "other10",
    label: "Fasilitas yang diharapkan?",
  },
  {
    type: "text",
    name: "other11",
    label: "Kapan anda dapat mulai bekerja?",
  },
  {
    type: "text",
    name: "other12",
    label: "Bersediakah anda di mutasikan?",
  },
  {
    type: "text",
    name: "other13",
    label: "Jika keluar kota / daerah?",
  },
  {
    type: "text",
    name: "other14",
    label: "Bersediakah anda bekerja lembur?",
  },
  {
    type: "text",
    name: "other15",
    label:
      "Apakah anda pernah menjalani pemeriksaan psikologis / psikotest? Apabila ya, kapan? Dimana? Untuk tujuan apa?",
  },
  {
    type: "text",
    name: "other16",
    label:
      "Apakah anda pernah berurusan dengan polisi karena tindak kejahatan?",
  },
  {
    type: "text",
    name: "other17",
    label:
      "Apabila terjadi sesuatu hal yang tidak diinginkan dikemudian hari, maka ahli waris yang anda tunjuk adalah?",
  },
  {
    type: "text",
    name: "other18",
    label: "Nama?",
  },
  {
    type: "text",
    name: "other19",
    label: "Alamat?",
  },
  {
    type: "text",
    name: "other20",
    label: "Hubungan dengan anda?",
  },
];

export const PERSONAL_JOB_APPLICATION_TABLE_FIELDS = {
  label: ["No", "Tanggal", ""],
  value: [{}, { type: "date", attribute: "date" }],
};

export const PERSONAL_INFORMATION_LETTER_TABLE_FIELDS = {
  
  label: ["No", "No SK", "Tanggal", "Jenis SK", "Action"],
  value: [{}, { type: "text", attribute: "fullnosk" }, { type: "date", attribute: "tanggal_berlaku_sk" }, {attribute: "prm_sk_description.name"}],
};

export const PERSONAL_REQUEST_TABLE_FIELDS = {
  label: ["No", "Tanggal", ""],
  value: [{}, { type: "date", attribute: "date" }],
};

export const PERSONNEL_REQUEST_FORM_FIELDS = [
  {
    type: "text",
    name: "kepada",
    label: "Kepada Yth",
  },
  {
    type: "text",
    name: "pengurus",
    label: "Pengurus Yayasan Ricci",
  },
  {
    type: "text",
    name: "up",
    label: "U.P. Pengadaan",
  },
  {
    type: "text",
    name: "ditempat",
    label: "Di tempat",
  },
];

export const PERSONNEL_ITEM_FORM = {
  name: [
    "",
    { name: "spek" },
    { name: "qtt" },
    { name: "hal" },
    { name: "sub" },
    { name: "rkp" },
    { name: "stok" },
  ],
};

export const PERSONAL_DISCONTINUANCE_TABLE_FIELDS = {
  label: ["No", "Tanggal", ""],
  value: [{}, { type: "date", attribute: "date" }],
};

export const PERSONNEL_DISCONTINUANCE_FORM_FIELDS = [
  {
    type: "text",
    name: "no",
    label: "No",
  },
  {
    type: "text",
    name: "hal",
    label: "Hal",
  },
  {
    type: "text",
    name: "nama",
    label: "Nama",
  },
  {
    type: "text",
    name: "jabatan",
    label: "Jabatan",
  },
  {
    type: "text",
    name: "jabatan",
    label: "Jabatan",
  },
  {
    type: "text",
    name: "nama2",
    label: "Nama",
  },
  {
    type: "text",
    name: "ktp",
    label: "No. KTP",
  },
  {
    type: "text",
    name: "gender",
    label: "Laki-laki / Perempuan",
  },
  {
    type: "text",
    name: "alamat",
    label: "Alamat",
  },
  {
    type: "text",
    name: "tglBerhenti",
    label: "Tanggal Berhenti",
  },
  {
    type: "text",
    name: "jabatanTerakhir",
    label: "Jabatan Terakhir",
  },
  {
    type: "text",
    name: "bekerjaMulai",
    label: "Bekerja Mulai",
  },
  {
    type: "text",
    name: "bekerjaSampai",
    label: "Bekerja Sampai",
  },
  {
    type: "text",
    name: "berhentiKarena",
    label: "Berhenti Karena",
  },
];

export const PERSONAL_DISCONTINUANCE_DESCRIPTION_TABLE_FIELDS = {
  label: ["No", "Tanggal", ""],
  value: [{}, { type: "date", attribute: "date" }],
};

export const PERSONNEL_DISCONTINUANCE_DESCRIPTION_FORM_FIELDS = [
  {
    type: "title",
    text: "Yang Bertanda Tangan Dibawah Ini",
    full: true,
  },
  {
    type: "text",
    name: "nama",
    label: "Nama",
  },
  {
    type: "text",
    name: "jabatan",
    label: "Jabatan",
  },
  {
    type: "text",
    name: "alamat",
    label: "Alamat",
  },
  {
    type: "empty",
  },
  {
    type: "title",
    text: "Menerangkan Sebenernya Bahwa",
    full: true,
  },
  {
    type: "text",
    name: "nama2",
    label: "Nama",
  },
  {
    type: "text",
    name: "nip",
    label: "NIP",
  },
  {
    type: "text",
    name: "jenisKelamin",
    label: "Jenis Kelamin",
  },
  {
    type: "text",
    name: "jabatan",
    label: "Jabatan",
  },
  {
    type: "text",
    name: "bekerjaSejak",
    label: "Bekerja Sejak",
  },
  {
    type: "text",
    name: "bekerjaSampai",
    label: "Bekerja Sampai",
  },
  {
    type: "text",
    name: "berhentiKarena",
    label: "Berhenti Karena",
  },
];

export const PERSONAL_ASSIGNMENT_TABLE_FIELDS = {
  label: ["No", "Tanggal", ""],
  value: [{}, { type: "date", attribute: "date" }],
};

export const PERSONAL_PAYROLLLIST_TABLE_FIELDS = {
  label: ["No", "No. G", "Nama Pegawai", ""],

  value: [
    {},
    { type: "string", attribute: "nip" },
    { type: "string", attribute: "nama_lengkap", disabled: true },
  ],
};

export const PERSONNEL_PAYROLL_FORM_FIELDS = [
  {
    type: "text",
    name: "no_g",
    label: "NO G",
    disabled: true,
  },
  {
    type: "text",
    name: "nama_pegawai",
    label: "Nama Pegawai",
    disabled: true,
  },
  {
    type: "text",
    name: "gaji_pokok",
    label: "Gaji Pokok",
    disabled: true,
  },
  {
    type: "text",
    name: "pangkat_golongan",
    label: "Golongan Kepangkatan",
    disabled: true,
  },
  {
    type: "text",
    name: "unit_kerja",
    label: "Unit Kerja",
    disabled: true,
  },
  {
    type: "text",
    name: "status",
    label: "Status",
    disabled: true,
  },

  {
    type: "text",
    name: "jabatan",
    label: "Jabatan",
    disabled: true,
  },
  {
    type: "text",
    name: "status_keluarga",
    label: "Status Keluarga",
    disabled: true,
  },

  {
    type: "text",
    name: "jumlah_jiwa",
    label: "Jumlah Jiwa",
    disabled: true,
  },
  {
    type: "text",
    name: "tunjangan_istri",
    label: "Tunjangan Istri",
    disabled: true,
  },
  {
    type: "text",
    name: "tujangan_anak",
    label: "Tunjangan Anak",
    disabled: true,
  },
  {
    type: "text",
    name: "tunjangan_beras",
    label: "Tunjangan Beras",
    disabled: true,
  },

  {
    type: "text",
    name: "tunjangan_struktural",
    label: "Tunjangan Struktural",
    disabled: true,
  },
  {
    type: "text",
    name: "tunjangan_fungsional",
    label: "Tunjangan Fungsional",
    disabled: true,
  },

  {
    type: "text",
    name: "tunjangan_khusus",
    label: "Tunjangan Khusus",
    disabled: true,
  },
  // {
  //   type: "text",
  //   name: "jumlah_pengasilan",
  //   label: "Jumlah Penghasilan",
  //   disabled: true,
  // },
  {
    type: "text",
    name: "subsidi_yadapen",
    label: "Subsidi Yadapen",
    disabled: true,
  },
  {
    type: "text",
    name: "subsidi_bpjs_kesehatan",
    label: "Subsidi BPJS Kesehatan",
    disabled: true,
  },
  {
    type: "text",
    name: "subsidi_bpjs_ketenaga_kerjaan",
    label: "Subsidi BPJS Ketenagakerjaan",
    disabled: true,
  },

  {
    type: "text",
    name: "potongan_yadapen",
    label: "Potongan Yadapen",
    disabled: true,
  },
  {
    type: "text",
    name: "potongan_restitusi",
    label: "Potongan Restitusi",
    disabled: true,
  },

  {
    type: "text",
    name: "potongan_bpjs_kesehatan",
    label: "Potongan BPJS Kesehatan",
    disabled: true,
  },
  {
    type: "text",
    name: "potongan_bpjs_ketenaga_kerjaan",
    label: "Potongan BPJS Ketenagakerjaan",
    disabled: true,
  },

  {
    type: "text",
    name: "ids",
    label: "IDS",
    disabled: true,
  },
  {
    type: "text",
    name: "idp",
    label: "IDP",
    disabled: true,
  },

  {
    type: "text",
    name: "khalawat",
    label: "Khalwat",
    disabled: true,
  },
  {
    type: "text",
    name: "bianglala",
    label: "Bianglala",
    disabled: true,
  },

  {
    type: "text",
    name: "jumlah_bersih",
    label: "Jumlah Bersih",
    disabled: true,
    full: true,
  },
  // {
  //   type: "text",
  //   name: "jumlah_dibayarkan",
  //   label: "Jumlah Dibayarkan",
  //   disabled: true,
  //   full: true,
  // },
];

export const PERSONNEL_PENGISIAN_PAYROLL_FORM_FIELDS = [
  {
    type: "text",
    name: "nip",
    label: "NIP",
  },
  {
    type: "text",
    name: "pph21",
    label: "PPh 21",
  },
  {
    type: "text",
    name: "angsuran",
    label: "No Angsuran",
  },
  {
    type: "text",
    name: "titipan",
    label: "Titipan",
  },
  {
    type: "text",
    name: "unit_kerja",
    label: "Unit Kerja",
  },
  {
    type: "text",
    name: "jumlah_penghasilan",
    label: "Jumlah Penghasilan",
    full: true,
  },
];

export const PERSONAL_PAYROLL_LIST_TABLE_FIELDS = {
  label: ["No", "Tahun", "Bulan", "Action"],
  value: [
    {},
    { type: "string", attribute: "year" },
    { type: "month_name", attribute: "month" },
  ],
};

export const PERSONNEL_ASSIGNMENT_FORM_FIELDS = [
  {
    type: "text",
    name: "nip",
    label: "NIP",
  },
  {
    type: "text",
    name: "nama",
    label: "Nama",
  },
  {
    type: "text",
    name: "tempatLahir",
    label: "Tempat / Tgl Lahir",
  },
  {
    type: "text",
    name: "ijazah",
    label: "Ijazah",
  },
  {
    type: "text",
    name: "golongan",
    label: "Golongan / Ruang Gaji",
  },
  {
    type: "text",
    name: "penugasanSebelumnya",
    label: "Penugasan Sebelumnya",
  },
  {
    type: "text",
    name: "mulai",
    label: "Mulai",
  },
  {
    type: "text",
    name: "penugasanBaru",
    label: "PenugasanBaru",
  },
];

export const PERSONAL_RETIRE_TABLE_FIELDS = {
  label: ["No", "Tanggal", ""],
  value: [{}, { type: "date", attribute: "date" }],
};

export const PERSONNEL_RETIRE_FORM_FIELDS = [
  {
    type: "text",
    name: "nip",
    label: "NIP",
  },
  {
    type: "text",
    name: "nama",
    label: "Nama",
  },
  {
    type: "text",
    name: "tempatLahir",
    label: "Tempat / Tgl Lahir",
  },
  {
    type: "text",
    name: "ijazah",
    label: "Ijazah",
  },
  {
    type: "text",
    name: "jabatan",
    label: "Jabatan",
  },
  {
    type: "text",
    name: "yadapen",
    label: "Nomor YADAPEN",
  },
  {
    type: "text",
    name: "jamsostek",
    label: "Nomor JAMSOSTEK",
  },
  {
    type: "text",
    name: "bpjs",
    label: "Nomor BPJS Kesehatan",
  },
  {
    type: "text",
    name: "ricci",
    label: "Masuk Ricci",
  },
  {
    type: "text",
    name: "dinas",
    label: "Mulai Dinas (SKPT)",
  },
  {
    type: "text",
    name: "diangkatSebagai",
    label: "Diangkat Sebagai",
  },
  {
    type: "text",
    name: "dipindahkan",
    label: "Dipindahkan",
  },
  {
    type: "text",
    name: "diberiKenaikan",
    label: "Diberi Kenaikan",
  },
  {
    type: "text",
    name: "diberhentikan",
    label: "Diberhentikan",
  },
  {
    type: "text",
    name: "golongan",
    label: "Golongan / Ruang Gaji",
  },
  {
    type: "text",
    name: "masaKerja",
    label: "Masa Kerja Golongan Pada",
  },
  {
    type: "text",
    name: "keterangan",
    label: "Keterangan",
  },
];

export const PERSONAL_PROMOTION_TABLE_FIELDS = {
  label: ["No", "Tanggal", ""],
  value: [{}, { type: "date", attribute: "date" }],
};

export const PERSONNEL_PROMOTION_FORM_FIELDS = [
  {
    type: "text",
    name: "nip",
    label: "NIP",
  },
  {
    type: "text",
    name: "nama",
    label: "Nama",
  },
  {
    type: "text",
    name: "tempatLahir",
    label: "Tempat / Tgl Lahir",
  },
  {
    type: "text",
    name: "ijazah",
    label: "Ijazah",
  },
  {
    type: "text",
    name: "jabatan",
    label: "Jabatan",
  },
  {
    type: "text",
    name: "yadapen",
    label: "Nomor YADAPEN",
  },
  {
    type: "text",
    name: "jamsostek",
    label: "Nomor JAMSOSTEK",
  },
  {
    type: "text",
    name: "bpjs",
    label: "Nomor BPJS Kesehatan",
  },
  {
    type: "text",
    name: "ricci",
    label: "Masuk Ricci",
  },
  {
    type: "text",
    name: "dinas",
    label: "Mulai Dinas (SKPT)",
  },
  {
    type: "text",
    name: "diangkatSebagai",
    label: "Diangkat Sebagai",
  },
  {
    type: "text",
    name: "dipindahkan",
    label: "Dipindahkan",
  },
  {
    type: "text",
    name: "diberiKenaikan",
    label: "Diberi Kenaikan",
  },
  {
    type: "text",
    name: "diberhentikan",
    label: "Diberhentikan",
  },
  {
    type: "text",
    name: "golongan",
    label: "Golongan / Ruang Gaji",
  },
  {
    type: "text",
    name: "masaKerja",
    label: "Masa Kerja Golongan Pada",
  },
  {
    type: "text",
    name: "keterangan",
    label: "Keterangan",
  },
];

export const PERSONAL_EMPLOYEE_CONTRACT_TABLE_FIELDS = {
  label: ["No", "Tanggal", ""],
  value: [{}, { type: "date", attribute: "date" }],
};

export const PERSONNEL_EMPLOYEE_CONTRACT_FORM_FIELDS = [
  {
    type: "text",
    name: "nip",
    label: "NIP",
  },
  {
    type: "text",
    name: "nama",
    label: "Nama",
  },
  {
    type: "text",
    name: "tempatLahir",
    label: "Tempat / Tgl Lahir",
  },
  {
    type: "text",
    name: "ijazah",
    label: "Ijazah",
  },
  {
    type: "text",
    name: "jabatan",
    label: "Jabatan",
  },
  {
    type: "text",
    name: "yadapen",
    label: "Nomor YADAPEN",
  },
  {
    type: "text",
    name: "jamsostek",
    label: "Nomor JAMSOSTEK",
  },
  {
    type: "text",
    name: "bpjs",
    label: "Nomor BPJS Kesehatan",
  },
  {
    type: "text",
    name: "ricci",
    label: "Masuk Ricci",
  },
  {
    type: "text",
    name: "dinas",
    label: "Mulai Dinas (SKPT)",
  },
  {
    type: "text",
    name: "diangkatSebagai",
    label: "Diangkat Sebagai",
  },
  {
    type: "text",
    name: "dipindahkan",
    label: "Dipindahkan",
  },
  {
    type: "text",
    name: "diberiKenaikan",
    label: "Diberi Kenaikan",
  },
  {
    type: "text",
    name: "diberhentikan",
    label: "Diberhentikan",
  },
  {
    type: "text",
    name: "golongan",
    label: "Golongan / Ruang Gaji",
  },
  {
    type: "text",
    name: "masaKerja",
    label: "Masa Kerja Golongan Pada",
  },
  {
    type: "text",
    name: "keterangan",
    label: "Keterangan",
  },
];

export const PERSONAL_TEACHER_CONTRACT_TABLE_FIELDS = {
  label: ["No", "Tanggal", ""],
  value: [{}, { type: "date", attribute: "date" }],
};

export const PERSONNEL_TEACHER_CONTRACT_FORM_FIELDS = [
  {
    type: "text",
    name: "nip",
    label: "NIP",
  },
  {
    type: "text",
    name: "nama",
    label: "Nama",
  },
  {
    type: "text",
    name: "tempatLahir",
    label: "Tempat / Tgl Lahir",
  },
  {
    type: "text",
    name: "ijazah",
    label: "Ijazah",
  },
  {
    type: "text",
    name: "jabatan",
    label: "Jabatan",
  },
  {
    type: "text",
    name: "yadapen",
    label: "Nomor YADAPEN",
  },
  {
    type: "text",
    name: "jamsostek",
    label: "Nomor JAMSOSTEK",
  },
  {
    type: "text",
    name: "bpjs",
    label: "Nomor BPJS Kesehatan",
  },
  {
    type: "text",
    name: "ricci",
    label: "Masuk Ricci",
  },
  {
    type: "text",
    name: "dinas",
    label: "Mulai Dinas (SKPT)",
  },
  {
    type: "text",
    name: "diangkatSebagai",
    label: "Diangkat Sebagai",
  },
  {
    type: "text",
    name: "dipindahkan",
    label: "Dipindahkan",
  },
  {
    type: "text",
    name: "diberiKenaikan",
    label: "Diberi Kenaikan",
  },
  {
    type: "text",
    name: "diberhentikan",
    label: "Diberhentikan",
  },
  {
    type: "text",
    name: "golongan",
    label: "Golongan / Ruang Gaji",
  },
  {
    type: "text",
    name: "masaKerja",
    label: "Masa Kerja Golongan Pada",
  },
  {
    type: "text",
    name: "keterangan",
    label: "Keterangan",
  },
];

export const PERSONAL_POSITION_TABLE_FIELDS = {
  label: ["No", "Tanggal", ""],
  value: [{}, { type: "date", attribute: "date" }],
};

export const PERSONNEL_POSITION_FORM_FIELDS = [
  {
    type: "text",
    name: "no1",
    label: "Nomor Surat",
  },
  {
    type: "text",
    name: "tahun1",
    label: "Tahun",
  },
  {
    type: "text",
    name: "nama1",
    label: "Nama",
  },
  {
    type: "text",
    name: "no2",
    label: "Nomor Surat",
  },
  {
    type: "text",
    name: "tahun2",
    label: "Tahun",
  },
  {
    type: "text",
    name: "nama2",
    label: "Nama",
  },
  {
    type: "text",
    name: "nama3",
    label: "Nama",
  },
  {
    type: "text",
    name: "nip3",
    label: "NIP",
  },
  {
    type: "text",
    name: "nama4",
    label: "Nama",
  },
  {
    type: "text",
    name: "nip4",
    label: "NIP",
  },
  {
    type: "text",
    name: "selaku1",
    label: "Selaku",
  },
  {
    type: "text",
    name: "alamat1",
    label: "Alamat",
  },
  {
    type: "text",
    name: "selaku2",
    label: "Selaku",
  },
  {
    type: "text",
    name: "alamat2",
    label: "Alamat",
  },
  {
    type: "text",
    name: "jabatan",
    label: "Jabatan",
  },
  {
    type: "text",
    name: "alamat3",
    label: "Alamat",
  },
];

export const PERSONAL_PERMANENT_TABLE_FIELDS = {
  label: ["No", "Tanggal", ""],
  value: [{}, { type: "date", attribute: "date" }],
};

export const PERSONNEL_PERMANENT_FORM_FIELDS = [
  {
    type: "title",
    text: "MEMUTUSKAN BAHWA NAMANYA TERCANTUM DIBAWAH INI",
    full: true,
  },
  {
    type: "text",
    name: "no",
    label: "Nomor Surat",
  },
  {
    type: "text",
    name: "nip",
    label: "NOP",
  },
  {
    type: "text",
    name: "nama",
    label: "Nama",
  },
  {
    type: "text",
    name: "tempatLahir",
    label: "Tempat / Tanggal Lahir",
  },
  {
    type: "text",
    name: "ijazah",
    label: "Ijasah / Pendidikan Terakhir",
  },
  {
    type: "text",
    name: "masuk",
    label: "Masuk Ricci",
  },
  {
    type: "text",
    name: "mulaiTanggal",
    label: "Mulai Tanggal",
  },
  {
    type: "empty",
  },
  {
    type: "title",
    text: "LAMPIRAN DAFTAR GAJI DARI SURAT KEPUTUSAN YAYASAN RICCI",
    full: true,
  },
  {
    type: "text",
    name: "no2",
    label: "No",
  },
  {
    type: "text",
    name: "mulaiTanggal2",
    label: "Mulai Tanggal",
  },
  {
    type: "text",
    name: "nama2",
    label: "Nama",
  },
  {
    type: "text",
    name: "tempatLahir2",
    label: "Tempat / Tanggal Lahir",
  },
  {
    type: "text",
    name: "ijazah2",
    label: "Ijasah / Pendidikan Terakhir",
  },
  {
    type: "text",
    name: "pangkatLama",
    label: "Pangkat dan Jabatan",
  },
  {
    type: "title",
    text: "LAMA",
    full: true,
  },
  {
    type: "text",
    name: "golonganLama",
    label: "Golongan dan Ruang Gaji",
  },
  {
    type: "text",
    name: "masaKerjaLama",
    label: "Masa Kerja Menurut",
  },
  {
    type: "text",
    name: "gajiPokokLama",
    label: "Gaji Pokok",
  },
  {
    type: "text",
    name: "gajiTambahanLama",
    label: "Gaji Tambahan",
  },
  {
    type: "title",
    text: "BARU",
    full: true,
  },
  {
    type: "text",
    name: "golonganBaru",
    label: "Golongan dan Ruang Gaji",
  },
  {
    type: "text",
    name: "masaKerjaBaru",
    label: "Masa Kerja Menurut",
  },
  {
    type: "text",
    name: "gajiPokokBaru",
    label: "Gaji Pokok",
  },
  {
    type: "text",
    name: "gajiTambahanBaru",
    label: "Gaji Tambahan",
  },
  {
    type: "text",
    name: "gajiLebih",
    label: "Masa Kerja Kelebihan Untuk Kenaikan Gaji Berikutnya",
  },
];

export const MANAGE_INFORMATION_LETTER_REQUIRED_FIELD = [
  {
    type: "select",
    name: "id_sk_descriptions",
    label: "Deskripsi SK",
  },
  {
    type: "text",
    name: "keterangan_sk",
    label: "Isi Deskripsi SK",
  },
  {
    type: "date",
    name: "tanggal_pembuatan_sk",
    label: "Tanggal Pembuatan SK",
  },
  {
    type: "text",
    name: "nama_ketua_yayasan",
    label: "Nama Ketua Yayasan",
  },
  {
    type: "text",
    name: "pangkat_golongan",
    label: "Pangkat / gol",
  },
  {
    type: "text",
    name: "pangkat",
    label: "Pangkat",
  },
  {
    type: "date",
    name: "tanggal_berlaku_sk",
    label: "Tanggal berlaku SK",
  },
  {
    type: "text",
    name: "lama_bekerja_tahun",
    label: "Lama tahun kerja",
  },
  {
    type: "text",
    name: "lama_bekerja_bulan",
    label: "Lama bulan kerja",
  },
  {
    type: "text",
    name: "gaji_pokok",
    label: "Gaji Pokok",
  },
  {
    type: "select",
    name: "status_kepegawaian",
    label: "Status Kepegawaian",
  },
  {
    type: "select",
    name: "jabatan",
    label: "Jabatan",
  },
  {
    type: "text",
    name: "nama_tabel_gaji",
    label: "Nama tabel gaji",
  },
  {
    type: "array",
    name: "tembusan",
    label: "Tembusan",
  },
  {
    type: "text",
    name: "keterangan",
    label: "Keterangan",
  },
];
