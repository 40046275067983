import { connect } from 'react-redux';
import InformationLetter from '../../../views/Personnel/Letter/InformationLetter.letter.view';
import { getInformationLetter, deleteInformationLetter } from '../../../states/thunks/personnel.thunk';
import { getDataListSK,getDataPegawai } from '../../../states/thunks/sk.thunks';
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    handleGetInformationLetter: payload => dispatch(getDataListSK(payload)),
    handleGetDataPegawai : payload => dispatch(getDataPegawai(payload)),
    // handleDeleteInformationLetter: payload => dispatch(deleteInformationLetter(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InformationLetter);
