import React, { PureComponent, Component, Fragment } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { commaFormatted } from "../../../../src/utils/transformer.util";
import moment from "moment";
import "moment/locale/id";

export default class ComponentToPrint extends Component {
  constructor(props) {
    super(props);
    const { datapegawai, kota } = this.props;
    this.state = {
      data_surat: {
        jenis_surat: "Surat Keputusan",
        no_surat: "",
        judul_tujuan: "",
        isi_tujuan: "",
        tgl: "",
        data_penerima: {
          nama: datapegawai.nama_lengkap,
          ijazah: datapegawai.pendidikan_terakhir,
          tempat_tgl_lahir:
            datapegawai.tempat_lahir +
            " , " +
            moment(new Date(datapegawai.tanggal_lahir)).format("D MMMM YYYY"),
          no_reg: "Reg.Yad." + datapegawai.no_yadapen,
          unit_kerja: datapegawai.unit_kerja.name,
        },
        data_lama: {
          pangkat: "",
          jabatan: "",
          gaji: "",
          kode: "",
          gaji_tertulis: "",
          tgl: "",
          masa: {
            tahun: "",
            bulan: "",
          },
        },
        data_baru: {
          pangkat: "",
          jabatan: "",
          gaji: "",
          gaji_tertulis: "",
          kode: "",
          tgl: "",
          masa: {
            tahun: "",
            bulan: "",
          },
        },
        keterangan: [],
        tembusan: [],
        tempat: datapegawai.unit_kerja.name,
        kota: kota,
        tgl_penetapan: "",
        ttd: "",
      },
    };
  }

  componentDidMount() {
    // console.log(this.props)
    this.getDataSK();
  }

  async getDataSK() {
    const terbilang = require("angka-menjadi-terbilang");
    const { handleGetDataSK, id, datapegawai } = this.props;
    const temp = await handleGetDataSK({
      nip: datapegawai.nip,
      id: id,
      unitkerja: datapegawai.unit_kerja.id_unit_kerja,
    });
    console.log(datapegawai);
    console.log(temp);
    const datask_new = temp.result.new_data;
    const datask_old = temp.result.old_data;
    const data = { ...this.state.data_surat };

    //header
    data.no_surat = "No. " + datask_new.no_g + " / " + datask_new.no_sk;
    data.tgl = moment(new Date(datask_new.tanggal_berlaku_sk)).format(
      "D MMMM YYYY"
    );
    data.judul_tujuan = datask_new.prm_sk_description.name;
    data.isi_tujuan = datask_new.keterangan_sk;

    console.log(datask_new.keterangan)
    //data lama
    if (datask_old.jabatan_name !== null) {
      let gaji_lama = datask_old.gaji_pokok 
      if(datask_old.status_kepegawaian_arr){
        gaji_lama = gaji_lama * datask_old.status_kepegawaian_arr.percent/100
      }
      data.data_lama.pangkat =
        datask_old.pangkat_name + " / " + datask_old.golongan;
      data.data_lama.jabatan = datask_old.jabatan_name;
      data.data_lama.gaji =
        "Rp." + commaFormatted(gaji_lama) + ",00";
      data.data_lama.gaji_tertulis =
        "(# " + terbilang(gaji_lama) + " rupiah" + " #)";
      data.data_lama.kode = datask_old.nama_tabel_gaji;
      data.data_lama.tgl = moment(
        new Date(datask_old.tanggal_berlaku_sk)
      ).format("D MMMM YYYY");
      data.data_lama.masa.tahun = datask_old.lama_bekerja_tahun;
      data.data_lama.masa.bulan = datask_old.lama_bekerja_bulan;
    }

    if(datask_new.id_sk_descriptions==="04"){ // jika diberhentikan maka masa kerja golongan lama diganti dengan masa kerja golongan baru
      data.data_lama.masa.tahun = datask_new.lama_bekerja_tahun;
      data.data_lama.masa.bulan = datask_new.lama_bekerja_bulan;
      data.data_lama.tgl = moment(new Date(datask_new.tanggal_berlaku_sk)).subtract(1, 'days').format(
        "D MMMM YYYY"
      );
    }

    //data baru
    if(datask_new.id_sk_descriptions!=="04"){
      let gaji_baru = datask_new.gaji_pokok 
      if(datask_new.status_kepegawaian_arr){
        gaji_baru = gaji_baru * datask_new.status_kepegawaian_arr.percent/100
      }
      data.data_baru.pangkat =
      datask_new.pangkat_name + " / " + datask_new.golongan;
      data.data_baru.jabatan = datask_new.jabatan_name;
      data.data_baru.gaji = "Rp." + commaFormatted(gaji_baru) + ",00";
      data.data_baru.gaji_tertulis =
        "(# " + terbilang(gaji_baru) + " rupiah" + " #)";
      data.data_baru.kode = datask_new.nama_tabel_gaji;
      data.data_baru.tgl = moment(new Date(datask_new.tanggal_berlaku_sk)).format(
        "D MMMM YYYY"
      );
      data.data_baru.masa.tahun = datask_new.lama_bekerja_tahun;
      data.data_baru.masa.bulan = datask_new.lama_bekerja_bulan;
    }
    
    //footer
    data.keterangan = datask_new.keterangan.split("\n");

    data.tembusan = datask_new.tembusan;
    data.tgl_penetapan = moment(
      new Date(datask_new.tanggal_pembuatan_sk)
    ).format("D MMMM YYYY");
    data.ttd = datask_new.nama_ketua_yayasan;
    
     console.log(data)
    this.setState({ data_surat: data });
    //console.log(datask_new.is_posting);
    if(datask_new.is_posting){
      this.props.handler()
    }
  }

  render() {
    return (
      <div className=" preview_sk__content">
        <div
          id="divPrint"
          style={{
            width: "auto",
            breakAfter: "avoid-page",
            border: "0px solid black",
          }}
        >
          <div className="preview_sk__content">
            <div className="preview_sk__header">
              <div className="preview_sk__header-title2">
                <div className="preview_sk__header-title2--left">
                  <div style={{marginBottom:5}}>YAYASAN PANGUDI LUHUR</div>
                  <div style={{marginBottom:5}}>Jl.Dr.Sutomo 4,Telp (024) 8314004</div>
                  <div style={{marginBottom:5}}>SEMARANG 50244</div>
                </div>
                <div className="preview_sk__header-title2--right">
                  <div style={{marginBottom:5}}>{this.state.data_surat.jenis_surat}</div>
                  <div style={{marginBottom:5}}>{this.state.data_surat.no_surat}</div>
                </div>
              </div>
              <div className="preview_sk__header-title">
                <div className="preview_sk__header-title--column">
                  <div className="preview_sk__header-title--column--bold"  style={{marginBottom:5}}>
                    PENGURUS YAYASAN PANGUDI LUHUR PUSAT DI SEMARANG
                  </div>
                  <div  style={{marginBottom:5}}>Seteleh menimbangkan dan mengingat seperlunya, dengan ini</div>
                  
                  <div className="preview_sk__header-title--column--bold"  style={{marginBottom:5}}>
                    MEMUTUSKAN
                  </div>
                  <div  style={{marginBottom:5}}>Menetapkan, bahwa Saudara yang namanya tersebut dalam surat
                  keputusan ini, terhitung mulai</div>
                  <div className="preview_sk__header-title--column--bold"  style={{marginBottom:5}}>
                    tanggal {this.state.data_surat.tgl}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <table className="preview_sk__table" style={{marginTop:20}}>
              <tr>
                <td valign="top">{this.state.data_surat.judul_tujuan}</td>
                <td valign="top" className="titik">
                  &nbsp;:&nbsp;
                </td>
                <td colSpan="4">{this.state.data_surat.isi_tujuan}</td>
              </tr>
              <tr>
                <td>Nama</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td colSpan="2">{this.state.data_surat.data_penerima.nama}</td>
                <td className="judul">
                  {/* {this.state.data_surat.data_penerima.no_reg} */}
                </td>
              </tr>
              <tr>
                <td>Ijazah</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td colSpan="4">
                  {this.state.data_surat.data_penerima.ijazah}
                </td>
              </tr>
              <tr>
                <td className="judul">Tempat / Tanggal Lahir</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td colspan="2">{this.state.data_surat.data_penerima.tempat_tgl_lahir}</td>
                <td className="judul">
                  {this.state.data_surat.data_penerima.no_reg}
                </td>
              </tr>
              <tr>
                <td>Unit Kerja</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td>
                  {this.state.data_surat.data_penerima.unit_kerja} di{" "}
                  {this.state.data_surat.kota}
                </td>
              </tr>
              {/* data lama */}
              <tr className="batas"></tr>
              <tr>
                <td colSpan="6" className="batas">
                  LAMA
                </td>
              </tr>
              <tr>
                <td className="judul">Pangkat/gol.ruang gaji</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td colSpan="2">{this.state.data_surat.data_lama.pangkat}</td>
                <td className="judul" style={{borderLeft:'1px solid black'}}>&nbsp;Masa kerja golongan pada </td>
              </tr>
              <tr>
                <td>Jabatan</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td colSpan="2">{this.state.data_surat.data_lama.jabatan}</td>
                <td style={{borderLeft:'1px solid black' }}>&nbsp;tgl. {this.state.data_surat.data_lama.tgl}</td>
              </tr>
              <tr>
                <td>Gaji Pokok</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td>{this.state.data_surat.data_lama.gaji}</td>
                <td style={{ width: 80 }}>
                  {this.state.data_surat.data_lama.kode}
                </td>
                <td style={{borderLeft:'1px solid black' }}>
                  &nbsp;{this.state.data_surat.data_lama.masa.tahun} tahun{" "}
                  {this.state.data_surat.data_lama.masa.bulan} bulan
                </td>
              </tr>
              <tr>
                <td></td>
                <td colSpan="4">
                  &emsp;&nbsp;{this.state.data_surat.data_lama.gaji_tertulis}
                </td>
              </tr>

              <tr className="batas"></tr>
              <tr>
                <td colSpan="6" className="batas">
                  BARU
                </td>
              </tr>
              <tr>
                <td className="judul">Pangkat/gol.ruang gaji</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td colSpan="2">{this.state.data_surat.data_baru.pangkat}</td>
                <td className="judul" style={{borderLeft:'1px solid black'}}>&nbsp; Masa kerja golongan pada </td>
              </tr>
              <tr>
                <td>Jabatan</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td colSpan="2">{this.state.data_surat.data_baru.jabatan}</td>
                <td style={{borderLeft:'1px solid black'}}>&nbsp; {this.state.data_surat.data_baru.tgl?'tgl.':''} {this.state.data_surat.data_baru.tgl}</td>
              </tr>
              <tr>
                <td>Gaji Pokok</td>
                <td className="titik">&nbsp;:&nbsp;</td>
                <td>{this.state.data_surat.data_baru.gaji}</td>
                <td style={{ width: 80 }}>
                  {this.state.data_surat.data_baru.kode}
                </td>
                <td style={{borderLeft:'1px solid black'}}>
                  &nbsp; {this.state.data_surat.data_baru.masa.tahun?this.state.data_surat.data_baru.masa.tahun + " tahun ":""}
                  {this.state.data_surat.data_baru.masa.bulan?this.state.data_surat.data_baru.masa.bulan +" bulan":""}
                </td>
              </tr>
              <tr>
                <td></td>
                <td colSpan="4">
                  &emsp;&nbsp;{this.state.data_surat.data_baru.gaji_tertulis}
                </td>
              </tr>
              <tr className="batas"></tr>
              <tr className="batas"></tr>
              <tr>
                <td valign="top">Keterangan Lain - lain</td>
                <td valign="top" className="titik">
                  &nbsp;:&nbsp;
                </td>
                <td colSpan="5" >
                  <ol>
                    {this.state.data_surat.keterangan.map((item, index) => {
                      console.log(item)
                      return (
                        <>
                          {/* <li key={index}>{item}</li> */}
                          <div>{item}</div>
                        </>
                      );
                    })}
                  </ol>
                </td>
              </tr>
            </table>

            <div className="preview_sk__header">
              <hr style={{marginTop:20, marginBottom:10}}></hr>
              <br />
              <div className="preview_sk__header-title2">
                <div className="preview_sk__header-title2--left">
                  <div style={{marginBottom:3}}>Yth, Sdr. {this.state.data_surat.data_penerima.nama}</div>
                  <div style={{marginBottom:3}}>&emsp;&emsp;d.a. {this.state.data_surat.tempat}</div>
                  <div style={{marginBottom:3}}>&emsp;&emsp;{this.state.data_surat.kota}</div>
                  <br />
                  <br />
                  <br />
                  <div style={{marginBottom:3}}>Tembusan kepada Yth.</div>
                  {this.state.data_surat.tembusan.map((item, index) => {
                    return (
                      <>
                        <div style={{marginBottom:3}}>{item.counter}. {item.note}</div>
                      </>
                    );
                  })}
                </div>
                <div className="preview_sk__header-title2--right">
                  <div style={{marginBottom:3}}>Ditetapkan di Semarang</div>
                  <div style={{marginBottom:3}}>Pada tanggal : {this.state.data_surat.tgl_penetapan}</div>
                  <div style={{marginBottom:3}}>Ketua,</div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  {this.state.data_surat.ttd}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ComponentToPrint.propTypes = {
  handelgetDataPegawai: PropTypes.func,
  // match: PropTypes.object.isRequired
};
ComponentToPrint.defaultProps = {
  handelgetDataPegawai: noop,
};
