import React, { Component } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PropTypes from 'prop-types';
import {
  map, noop, isEmpty,
  filter, find, has, pickBy,
  cloneDeep,
} from 'lodash';
import {
  commaFormatted,
  normalizeAmount,
  datetimeFormat,
} from '../../../../utils/transformer.util';
import {
  Select, Input, SearchSelect, Button, Textarea, Checkbox,
} from '../../../../components/base';
import FundCheckList from './components/FundCheckList.component';
import FundRequestFormTableHeaders from './components/FundRequestFormTableHeaders.component';
import language from '../../../../languages';
import { printJournal } from '../../../../utils/print.util';
import { errorAlert } from '../../../../utils/alert.util';

const MySwal = withReactContent(Swal);

const calculateTotal = (list = null) => {
  let total = 0;
  if (!isEmpty(list)) {
    Object.keys(list).forEach((key) => {
      let item = list[key];

      if (list[key] && list[key].amount) {
        item = list[key].amount;
      }
      if (item) {
        total += parseFloat(item);
      }
    });
  }
  return total;
};

export default class ManageFundRequest extends Component {
  constructor(props) {
    super(props);

    const { user } = props;
    const { user_group } = user;
    const { permissions } = user_group;

    this._setForm = this._setForm.bind(this);
    this._onFormChange = this._onFormChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._getData = this._getData.bind(this);
    this._addListItem = this._addListItem.bind(this);
    this._getCoaOptions = this._getCoaOptions.bind(this);
    this._renderButtons = this._renderButtons.bind(this);
    this._onSubmitApproval = this._onSubmitApproval.bind(this);
    this._onSelectCoa = this._onSelectCoa.bind(this);
    this._showModal = this._showModal.bind(this);
    this._renderFundChecklist = this._renderFundChecklist.bind(this);
    this._onCloseModal = this._onCloseModal.bind(this);
    this._onDelete = this._onDelete.bind(this);
    this._onCheckAll = this._onCheckAll.bind(this);
    this._setRef = this._setRef.bind(this);
    this._onReject = this._onReject.bind(this);
    this._onCheckDetail = this._onCheckDetail.bind(this);
    this._setSelectedDetail = this._setSelectedDetail.bind(this);
    this._setRemarks = this._setRemarks.bind(this);
    this._confirmReject = this._confirmReject.bind(this);
    this._renderWorkflowHistory = this._renderWorkflowHistory.bind(this);
    this._renderFormHeader = this._renderFormHeader.bind(this);
    this._setTableConfig = this._setTableConfig.bind(this);
    this._resetForm = this._resetForm.bind(this);

    this.remarks = React.createRef();
    this.checkboxRefs = [];

    this.state = {
      filters: {
        head: null,
        periode: new Date().getFullYear(),
        coa: null,
      },
      form: {
        value: {
          amount: 0,
          total_approval: 0,
          total: 0,
          periode: null,
          year: new Date().getFullYear(),
          selectedBudget: null,
          details: {},
          recommendations: {},
        },
        error: {},
      },
      coa: [],
      budgets: [],
      workflow: [],
      selectedCoa: {},
      selectedBudgetDetails: {},
      availableBudgetDetails: {},
      checkedAll: false,
      checkedDetails: [],
      remarks: '',
      is_approved: false,
      school_unit: {},
    };

    this.tableConfig = {
      showEdit: false,
      showApprovalCol: false,
      showEditRecommendation: false,
      showRecommendation: false,
      showView: false,
    };

    this.permissions = {
      canEditRecommendation:
        permissions.includes('edit_fund_request_recommendation'),
      canApprove:
        permissions.includes('approve_fund_request'),
      canEdit:
        permissions.includes('edit_fund_request'),
      canView:
        permissions.includes('view_fund_request'),
      canEditYear:
        permissions.includes('backdate_journal'),
    };
  }

  componentDidMount() {
    const {
      location,
    } = this.props;
    const { data = {} } = location.state;

    if (data.id) {
      this._getData(data.id);
    } else {
      this._setTableConfig();
    }

    this._getCoaOptions();
  }

  async _getData(id) {
    const { handleGetFundRequestById } = this.props;
    const res = await handleGetFundRequestById({ id });
    this._getCoaOptions();
    this._setForm(res);
  }

  async _getCoaOptions(keyword = null) {
    const { handleGetFundRequestAvailableCoa } = this.props;
    const { form } = this.state;
    const { value } = form;
    const { periode, year } = value;
    const res = await handleGetFundRequestAvailableCoa({
      keyword,
      periode,
      year,
    });

    return res;
  }

  _setRemarks(event) {
    const { value } = event.target;

    this.setState(prevState => ({
      ...prevState,
      remarks: value,
    }));
  }

  _setSelectedDetail(state = {}, checked = false, detailId = null) {
    const { form } = state;
    const { details = {} } = form.value;
    const newState = { ...state };
    const newRecommendations = newState.form.value.recommendations || {};

    if (!checked) {
      delete newState.checkedDetails[detailId];
      delete newRecommendations[detailId];
    } else {
      const detail = find(details, (o, key) => parseInt(key, 10) === parseInt(detailId, 10));
      newState.checkedDetails[detailId.toString()] = detail;
      newRecommendations[detailId.toString()] = detail.amount;
      newState.form.value.details[detailId.toString()].recommendation = detail.amount;
    }
    newState.form.value.recommendations = newRecommendations;
    newState.form.value.total_approval = calculateTotal(newRecommendations);

    return newState;
  }

  _onCheckAll(event) {
    const { target } = event;
    const detailIds = this.checkboxRefs;
    let newState = { ...this.state };
    const isCheckedAll = target.checked;

    Object.keys(detailIds).forEach((detailId) => {
      detailIds[detailId].checked = isCheckedAll;
      newState = this._setSelectedDetail(newState, isCheckedAll, detailId);
    });

    this.setState(() => ({
      ...newState,
      checkedAll: target.checked,
    }));
  }

  _onCheckDetail(event) {
    const { target } = event;
    const { value, checked } = target;
    let newState = { ...this.state };

    newState = this._setSelectedDetail(newState, checked, value);

    this.setState({
      ...newState,
    });
  }

  _setRef(el) {
    if (el === null || !el) {
      return;
    }
    const { input } = el;
    const { value } = input;
    this.checkboxRefs[value] = input;
  }

  _addListItem() {
    const { form } = this.state;
    const newForm = { ...form };
    const { value } = newForm;

    if (typeof value.details === 'undefined') {
      value.details = {};
    }

    this.setState(prevState => ({
      ...prevState,
      form: {
        value: {
          ...prevState.form.value,
          ...newForm,
        },
      },
    }), () => {
      this.calculateTotal();
    });
  }

  _showModal() {
    const { selectedCoa } = this.state;
    if (Object.keys(selectedCoa).length > 0) {
      MySwal.fire({
        title: <p>Pilih anggaran</p>,
        html: this._renderFundChecklist(),
      });
    }
    return false;
  }

  _setForm(data) {
    const {
      id = null, periode = null, details = {}, workflow = [],
      selectedBudgetDetails = {}, recommendations = {}, amount = 0,
      total_approval = 0, is_approved = false, school_unit, year,
    } = data;
    let checkedAll = false;
    const notEmptyRecommendations = pickBy(recommendations, value => value > 0);
    if (Object.keys(selectedBudgetDetails).length
    === Object.keys(notEmptyRecommendations).length) {
      checkedAll = true;
    }
    const checkedDetails = notEmptyRecommendations || {};
    this.setState({
      form: {
        value: {
          id,
          amount,
          periode,
          details: Array.isArray(details) ? {} : details,
          recommendations: recommendations || {},
          total_approval,
          year,
        },
        error: {},
      },
      workflow,
      selectedBudgetDetails,
      availableBudgetDetails: {},
      checkedAll,
      checkedDetails,
      is_approved,
      school_unit,
    }, () => {
      this._setTableConfig();
    });
  }

  _setTableConfig() {
    let { tableConfig } = this;
    const { permissions } = this;
    const { location, user } = this.props;
    const { user_group } = user;
    const userGroup = user_group.name;
    const { data } = location.state || {};
    const { is_approved = false } = data || {};
    const { workflow } = this.state;
    const workflowLength = workflow.length || 0;

    if (is_approved) {
      tableConfig = {
        showEdit: false,
        showEditRecommendation: false,
        showRecommendation: true,
        showApprovalCol: true,
      };
    } else if (workflowLength > 0) {
      const { next_role, prev_role } = workflow[workflowLength - 1];

      if (userGroup !== next_role) {
        tableConfig.showEdit = false;
        tableConfig.showEditRecommendation = false;
        tableConfig.showView = true;

        if (permissions.canEditRecommendation) {
          if (userGroup === prev_role) {
            tableConfig.showRecommendation = true;
          }
        }
      } else {
        if (permissions.canEditRecommendation) {
          tableConfig.showEditRecommendation = true;
          tableConfig.showRecommendation = true;
        }

        if (permissions.canApprove) {
          tableConfig.showApprovalCol = true;
          tableConfig.showRecommendation = true;
        }

        if (permissions.canEdit) {
          tableConfig.showEdit = true;
        }
      }
    } else if (permissions.canEdit) {
      tableConfig.showEdit = true;
    }
    this.tableConfig = tableConfig;
  }

  async _onSelectCoa(event) {
    const { target } = event;
    const { value } = target;
    const { form } = this.state;
    const { periode, year, id } = form.value;
    const { handleGetAvailableBudgetDetails } = this.props;
    const budgetDetails = await handleGetAvailableBudgetDetails({
      coa: value.code,
      month: periode,
      year,
      id,
    });
    this.setState(prevState => ({
      ...prevState,
      selectedCoa: value,
      availableBudgetDetails: {
        [value.code]: { ...budgetDetails },
      },
    }));
  }

  async _onDelete(id) {
    const { handleDeleteFundRequestDetail } = this.props;
    const { form, selectedBudgetDetails } = this.state;
    const { value } = form;
    const newDetails = { ...value.details };
    delete newDetails[id];
    delete selectedBudgetDetails[id];
    if (value.id) {
      await handleDeleteFundRequestDetail({
        budget_detail_id: id,
        id: value.id,
      });
    }
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          amount: calculateTotal(newDetails),
          details: { ...newDetails },
        },
      },
      selectedBudgetDetails,
    }));
  }

  async _onFormChange(event, fieldName) {
    const {
      name,
      value,
      dataset,
    } = event.target;
    const {
      inputType = 'text', arrayPosition = null,
    } = dataset;
    const { form } = this.state;
    const { value: formValue } = form;
    const newForm = cloneDeep(form);

    this.setState((prevState) => {
      let formattedValue = value;

      if (inputType === 'number') {
        formattedValue = normalizeAmount(value);
      }

      if (arrayPosition !== null) {
        if (!newForm.value.details[arrayPosition]) {
          newForm.value.details[arrayPosition] = {};
        }
        if (name === 'amount') {
          newForm.value.details[arrayPosition][name] = formattedValue;
          newForm.value.amount = calculateTotal(newForm.value.details);
        } else if (name === 'recommendations') {
          newForm.value.recommendations[arrayPosition.toString()] = formattedValue;
          newForm.value.total_approval = calculateTotal(newForm.value.recommendations);
        } else {
          newForm.value.details[arrayPosition][name] = formattedValue;
        }
      } else {
        newForm.value[name] = formattedValue;
      }

      const error = {
        ...prevState.form.error,
        [name]: '',
      };

      if (fieldName) {
        error[fieldName] = filter(error[fieldName], (err) => {
          if (err) {
            return err.length;
          }
          return {};
        });
      }

      return {
        ...prevState,
        form: {
          value: {
            ...prevState.form.value,
            ...newForm.value,
          },
          error: {
            ...prevState.form.error,
            [name]: '',
          },
        },
      };
    }, () => {
      const currentYear = new Date().getFullYear();
      if (name === 'year'
      || (name === 'periode' && formValue.year !== currentYear)) {
        this._resetForm();
      }
    });
  }

  _onCloseModal(detailState) {
    const { selectedBudgetDetails, form } = this.state;
    const { value } = form;
    const { details } = value;
    const { selected, unchecked } = detailState;

    if (selected) {
      Object.keys(selected).forEach((key) => {
        selectedBudgetDetails[key] = selected[key];
      });
    }
    if (unchecked) {
      Object.keys(unchecked).forEach((key) => {
        delete selectedBudgetDetails[key];
        delete details[key];
      });
    }
    this.setState(prevState => ({
      ...prevState,
      form: {
        ...prevState.form,
        value: {
          ...prevState.form.value,
          amount: calculateTotal(details),
          details,
        },
      },
      selectedBudgetDetails: {
        ...selectedBudgetDetails,
        ...selected,
      },
    }));
  }

  _onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;
    const {
      handleAddFundRequest,
      history, location,
    } = this.props;
    const error = null;

    const { isEdit = false } = location.state;
    if (isEmpty(error)) {
      if (isEdit) {
        handleAddFundRequest(form.value, history.goBack);
      } else {
        handleAddFundRequest(form.value, history.goBack);
      }
    } else {
      errorAlert({
        title: isEdit ? 'Gagal Mengubah' : 'Gagal Membuat',
        errorMessage: 'Periksa lengkapi form',
      });
    }
  }

  async _onSubmitApproval() {
    const { user } = this.props;
    const { permissions } = user.user_group;
    const { form } = this.state;
    const {
      handleSubmitApproval,
      location,
      history,
      handleAddFundRequest,
    } = this.props;
    const { data } = location.state;

    if (typeof data === 'undefined'
      || permissions.includes('edit_fund_request_recommendation')
      || permissions.includes('edit_fund_request')
    ) {
      const res = await handleAddFundRequest(form.value);
      this.setState(prevState => (
        {
          ...prevState,
          form: {
            ...prevState.form,
            value: {
              ...prevState.form.value,
              id: res.id,
              amount: res.amount,
              periode: res.periode,
              details: Array.isArray(res.details) ? {} : res.details,
              recommendations: res.recommendations || {},
              total_approval: res.total_approval || 0,
              year: res.year || 0,
            },
          },
        }
      ));
      if (res.id) {
        await handleSubmitApproval({
          id: res.id,
        }, history.goBack);
      }
    } else {
      await handleSubmitApproval({
        id: data.id,
      }, history.goBack);
    }
  }

  _confirmReject() {
    const el = this.remarks.current.input;
    el.classList.remove('hidden');
    MySwal.fire({
      title: 'Masukkan remarks untuk menolak permohonan dana',
      html: el,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        this._onReject(el.value);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.close();
      }
    });
  }

  _onReject(remarks) {
    const { handleReject, history, location } = this.props;
    const { data } = location.state;

    handleReject({
      id: data.id,
      remarks,
    }, history.goBack);
  }

  async _resetForm() {
    this.setState(prevState => (
      {
        ...prevState,
        form: {
          ...prevState.form,
          value: {
            ...prevState.form.value,
            amount: 0,
            total_approval: 0,
            total: 0,
            selectedBudget: null,
            details: {},
            recommendations: {},
          },
        },
        availableBudgetDetails: {},
        selectedBudgetDetails: {},
      }
    ), () => {
      this._getCoaOptions();
    });
  }

  _renderFilters(form, isEdit) {
    const { value, error } = form;
    const { periode, year } = value;
    const yearOptions = [];
    const monthOptions = [];
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];

    for (let i = 2020; i <= currentYear; i += 1) {
      yearOptions.push({
        value: i,
        label: i,
      });
    }

    if (periode) {
      monthOptions.push({
        label: months[periode],
        value: periode,
      });
    }

    if (year.toString() !== currentYear.toString()) {
      for (let i = 0; i < 12; i += 1) {
        if (i !== periode) {
          monthOptions.push({
            label: months[i],
            value: i,
          });
        }
      }
    } else {
      monthOptions.push({
        label: months[currentMonth],
        value: currentMonth,
      });

      if (currentMonth !== 11) {
        monthOptions.push({
          label: months[currentMonth + 1],
          value: currentMonth + 1,
        });
      }
    }

    return (
      <div className="manage-fund-request__row-form">
        <div className="manage-fund-request__form-content">
          { this.permissions.canEditYear && (
            <>
              <Select
                disabled={!isEdit}
                name="year"
                label="Tahun"
                data={yearOptions}
                onChange={this._onFormChange}
                placeholder="Pilih Tahun"
                value={year || ''}
                error={error.year || ''}
              />
              &nbsp;
            </>
          )}
          <Select
            disabled={!isEdit}
            name="periode"
            label="Periode"
            data={monthOptions}
            onChange={this._onFormChange}
            placeholder="Pilih periode"
            value={periode || ''}
            error={error.periode || ''}
          />
        </div>
      </div>
    );
  }

  _renderButtons() {
    const { user, location } = this.props;
    const { data = {} } = location.state;
    const { user_groups_id, user_group } = user;
    const { workflow = [] } = data;
    const lastWorkflow = workflow[workflow.length - 1] || {};
    const firstWorkflow = workflow[0] || {};
    const { permissions = [] } = user_group;
    let canApprove = permissions.includes('approve_fund_request');
    const roleMap = {
      'Keuangan Sekolah': 'Bendahara Unit',
      'Kepala Sekolah': 'Kepala Sekolah',
      'Bendahara Perwakilan': 'Bendahara Perwakilan',
      'Korektor Perwakilan': 'Perwakilan',
      'Ketua Perwakilan': 'Ketua Perwakilan',
      'Bendahara Pusat': 'Bendahara Pusat',
      'Korektor Pusat': 'Korektor Pusat',
      'Manager Keuangan': 'Manager Keuangan',
      'Accounting Pusat': 'Akunting Pusat',
      Bendahara: 'Pengurus',
    };

    if (user_group.name === 'Ketua Perwakilan') {
      canApprove = firstWorkflow.prev_role !== 'Bendahara Perwakilan';
    }

    if (!lastWorkflow.next_role && !lastWorkflow.is_done) {
      return [
        <div key="button_save">
          <Button
            type="submit"
            title="Save"
          />
        </div>,
        <div key="button_submit">
          <Button
            type="button"
            onClick={this._onSubmitApproval}
            title={(user_group.name !== 'Bendahara') ? 'Submit' : 'Approve'}
          />
        </div>,
      ];
    }
    if ((lastWorkflow.next_role === user_group.name) && !lastWorkflow.is_done) {
      const buttons = [];

      if (permissions.includes('edit_fund_request') || permissions.includes('edit_fund_request_recommendation')) {
        buttons.push([
          <div key="button_save">
            <Button
              type="submit"
              title="Save"
            />
          </div>,
        ]);
      }

      buttons.push([
        <div key="button_submit">
          <Button
            type="button"
            onClick={this._onSubmitApproval}
            title={(!canApprove) ? 'Submit' : 'Approve'}
          />
        </div>,
      ]);

      if (permissions.includes('reject_fund_request') && !lastWorkflow.is_done) {
        buttons.push([
          <div key="button_save">
            <Button
              type="button"
              onClick={this._confirmReject}
              title="Reject"
            />
          </div>]);
      }

      if (user_groups_id) {
        return buttons;
      }
    } else {
      if (lastWorkflow.is_done) {
        return (
          <div>
            <i>Permohonan dana telah setujui oleh {roleMap[lastWorkflow.next_role]}</i>
          </div>
        );
      }
      let status = 'Permohonan dana telah diajukan ke ';
      if (lastWorkflow.action === 'reject') {
        status = 'Permohonan dana telah dikembalikan ke ';
      }
      return (
        <div>
          <i>{`${status}${roleMap[lastWorkflow.next_role]}`}</i>
        </div>
      );
    }


    return false;
  }

  _renderFormHeader() {
    const { school_unit, form, budget = {} } = this.state;
    const months = [
      'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli',
      'Agustus', 'September', 'Oktober', 'November', 'Desember',
    ];

    if (typeof form.value.id === 'undefined') {
      return false;
    }

    return (
      <div className="form_header">
        <h1>
          Permohonan Dana
        </h1>
        {
          typeof budget.periode !== 'undefined' && (
            <h2>
              Anggaran Tahun {budget.periode}
            </h2>
          )
        }
        <h2>
          {school_unit.school_unit.name}
        </h2>
        <h2>
          Periode {months[form.value.periode]}
        </h2>
      </div>
    );
  }

  _renderFundChecklist() {
    const {
      selectedCoa = {}, availableBudgetDetails = {},
      selectedBudgetDetails = {},
    } = this.state;
    const budgetDetails = { ...availableBudgetDetails[selectedCoa.code] } || {};

    return (
      <FundCheckList
        budgets={budgetDetails}
        selectedBudgetDetails={selectedBudgetDetails}
        onChange={this._onCloseModal}
      />
    );
  }

  _renderWorkflowHistory(workflow) {
    const rows = [];
    const roleMap = {
      'Keuangan Sekolah': 'Bendahara Unit',
      'Kepala Sekolah': 'Kepala Sekolah',
      'Bendahara Perwakilan': 'Bendahara Perwakilan',
      'Korektor Perwakilan': 'Perwakilan',
      'Ketua Perwakilan': 'Ketua Perwakilan',
      'Bendahara Pusat': 'Bendahara Pusat',
      'Korektor Pusat': 'Korektor Pusat',
      'Manager Keuangan': 'Manager Keuangan',
      'Accounting Pusat': 'Akunting Pusat',
      'Bendahara': 'Pengurus',
    };
    if(workflow) {
      workflow.forEach((item, idx) => {
        const { prev_role, next_role, action, updated_at, remarks, is_done} = item;

        let flowAction = (action === 'reject') ?
          'mengembalikan ke' : ' mengajukan ke';
        let flowValue = `${roleMap[prev_role]} ${flowAction} ${roleMap[next_role]}`;
        if(is_done) {
          flowValue = `Permohonan dana telah disetujui oleh ${roleMap[next_role]}`;
        }
        rows.push([
          <tr key={`workflow_history_item_${idx}`}>
            <td>{idx+1}</td>
            <td>{flowValue}</td>
            <td>{remarks}</td>
            <td>{datetimeFormat(updated_at)}</td>
          </tr>
        ])
      });
      return (
        <table className="table">
          <thead>
            <tr>
              <th>No.</th>
              <th>Flow</th>
              <th>Catatan</th>
              <th>Tanggal</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>
      )
    }

    return false;
  }

  render() {
    const {
      form, selectedCoa, workflow = [],
      selectedBudgetDetails, checkedDetails, checkedAll, remarks,
    } = this.state;
    const {
      showEdit,
      showEditRecommendation,
      showRecommendation,
    } = this.tableConfig;
    const { details, recommendations, total_approval } = form.value;
    const {
      options, user, location,
    } = this.props;
    const locationState = location.state;
    const { user_group } = user;
    const { permissions } = user_group;
    const isEdit = permissions.includes('edit_fund_request') && locationState.isEdit;
    let idx = 1;

    return (
      <div className="manage-fund-request">
        {
          typeof form.value.id === 'undefined' && (
            <h1>
              Buat Permohonan Dana
            </h1>
          )
        }
        {this._renderFormHeader()}
        <form onSubmit={this._onSubmit}>
          <Textarea
            onChange={this._setRemarks}
            ref={this.remarks}
            classNames="hidden remarks"
            value={remarks}
          />
          { this._renderFilters(form, isEdit) }
          <table className="table" cellPadding="0" cellSpacing="0" border="0">
            <FundRequestFormTableHeaders
              showEdit={showEdit}
              onCheckAll={this._onCheckAll}
              checkAll={checkedAll}
              showEditRecommendation={showEditRecommendation}
              showRecommendation={showRecommendation}
            />
            <tbody>
              { showEdit === true && (
                <tr>
                  <td colSpan={(showEdit && showRecommendation) ? 10 : (showEdit) ? 9 : 7  }>
                    <div className="manage-budget__add-more">
                      <div className="manage-fund-request__form-content">
                        <SearchSelect
                          noMargin
                          async={false}
                          name="parameter_code"
                          list={options.coa.list}
                          onChange={this._onSelectCoa}
                          placeholder="Pilih Kode Akun"
                          value={selectedCoa}
                          labelName="title"
                          valueName="code"
                        />&nbsp;
                        <Button
                          disabled={(Object.keys(selectedCoa).length > 0) ? false : true}
                          onClick={this._showModal}
                          title={language.translate.FINANCE__BUDGETING__ADD_LINE__TITLE}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              {
              map(selectedBudgetDetails, (data, key) => (
                <tr key={key}>
                  {(showEditRecommendation) && (
                    <td className="col_sm">
                      <Checkbox
                        noMargin
                        data-input-array
                        data-array-position={key}
                        name="selectedBudget"
                        onChange={this._onCheckDetail}
                        value={key}
                        checked={has(checkedDetails, key)}
                        ref={this._setRef}
                      />
                    </td>
                  )}
                  <td className="col_sm">{idx++}</td>
                  <td>
                    {data.code_of_account}
                  </td>
                  <td>
                    {data.semester}
                  </td>
                  <td>
                    {!isEmpty(data) && data.desc}
                  </td>
                  <td className="nominal">
                    {!isEmpty(data) && commaFormatted(data.ypl)}
                  </td>
                  <td className="nominal">
                    {!isEmpty(data) && (!isEmpty(data.remains)
                      ? commaFormatted(data.remains)
                      : commaFormatted(data.ypl)) }
                  </td>
                  <td className="nominal">
                    {!isEmpty(data) && commaFormatted(data.allowed_fund_request)}
                  </td>
                  <td className="nominal">
                    <Input
                      noMargin
                      data-input-array
                      data-array-position={key}
                      data-input-type="number"
                      isNumber
                      edit={showEdit}
                      name="amount"
                      onChange={this._onFormChange}
                      value={
                        details[key] ? details[key].amount : ''
                      }
                    />
                  </td>
                  {showRecommendation && (
                    <td className="nominal">
                      <Input
                        noMargin
                        data-input-array
                        data-array-position={key}
                        data-input-type="number"
                        isNumber
                        edit={showEditRecommendation}
                        name="recommendations"
                        onChange={this._onFormChange}
                        value={recommendations[key] ? recommendations[key] : null}
                      />
                    </td>
                  )}
                  { showEdit && (
                    <td>
                      <Button onClick={() => this._onDelete(key)} icon="icon-trash-o" />
                    </td>
                  )}
                </tr>
              ))}
              <tr className="total">
                <td
                  colSpan={(showEditRecommendation) ? 8 : (showRecommendation || showEdit ) ? 7 : 7}
                >
                  Total
                </td>
                <td className="nominal">{commaFormatted(form.value.amount)}</td>
                { showEdit && !showRecommendation && (
                  <td />
                )}
                {showRecommendation && (
                  <td className="nominal">{ commaFormatted(total_approval) }</td>
                )}
              </tr>
            </tbody>
          </table>
          <div />
          <br />
          <div className="workflow_history">
            <h2>Workflow</h2>
            {this._renderWorkflowHistory(workflow)}
          </div>
          <div className="buttons-wrapper">
            {this._renderButtons()}

            { typeof form.value.id !== 'undefined' && (
              <div className="print">
                <Button
                  title="Cetak"
                  onClick={() => {
                    printJournal('form_header', 'table', '', {
                      pageSize: 'A4 portrait',
                      printHeader: {
                        repeated: false,
                      },
                    });
                  }}
                />
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}
ManageFundRequest.propTypes = {
  handleGetAvailableBudgetDetails: PropTypes.func,
  handleGetFundRequestAvailableCoa: PropTypes.func,
  handleAddFundRequest: PropTypes.func,
  handleGetFundRequestById: PropTypes.func,
  handleSubmitApproval: PropTypes.func,
  handleReject: PropTypes.func,
  handleDeleteFundRequestDetail: PropTypes.func,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  options: PropTypes.object,
  user: PropTypes.object,
};

ManageFundRequest.defaultProps = {
  handleGetAvailableBudgetDetails: noop,
  handleGetFundRequestAvailableCoa: noop,
  handleAddFundRequest: noop,
  handleGetFundRequestById: noop,
  handleSubmitApproval: noop,
  handleReject: noop,
  handleDeleteFundRequestDetail: noop,
  options: {},
  user: {},
};
